import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import AlertIndicator from 'src/components/AlertIndicator';
import { STATUS_FILTERS } from '../../modules/filterUtils';
import {
  sortWithEmptyNumbers,
  sortWithEmptyString,
} from 'src/utils/sorters/utils';
import { MonitoredPatient, PageColumn } from './types';
import { patientPageColumnsMessages } from './constants';
import { AlertIndicatorCell, PatientName } from './styled';
import { redirectToPatientIDB } from 'src/utils/patientHelpers';
import PatientConsent from 'src/routes/NursesStation/components/MonitoredPersons/PatientConsent';
import { Tooltip } from 'antd';

const calculateAge = (dateOfBirth: Date) => {
  const ageDifMs = Date.now() - dateOfBirth.getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getPatientPageColumns = (
  intl: IntlShape,
): PageColumn<MonitoredPatient>[] => [
  {
    title: intl.formatMessage(patientPageColumnsMessages.room),
    dataIndex: 'room',
    key: 'room',
    render: (_, record) => record.roomName || '--',
    sorter: (a, b) =>
      sortWithEmptyString(a.roomName, b.roomName, { numeric: true }),
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.bed),
    dataIndex: 'bed',
    key: 'bed',
    render: (_, record) => record.bedName || '--',
    sorter: (a, b) =>
      sortWithEmptyString(a.bedName, b.bedName, { numeric: true }),
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.fullName),
    dataIndex: 'fullName',
    key: 'fullName',
    render: (_, record) =>
      record?.deviceId ? (
        <PatientName
          data-cy={`dashboard-btn-${record.id}`}
          onClick={() => {
            if (!record?.isConsented) {
              return;
            }
            redirectToPatientIDB(record.id);
          }}
        >
          <Tooltip
            title={
              !record?.isConsented ? (
                <FormattedMessage
                  {...patientPageColumnsMessages.noConsentTooltip}
                />
              ) : null
            }
          >
            {record.fullName}
          </Tooltip>
        </PatientName>
      ) : (
        record.fullName
      ),
    sorter: (a, b) => a.fullName.localeCompare(b.fullName),
    filtered: true,
    onFilter: (value, record) =>
      record.locationName?.toLowerCase().includes(value.toLowerCase()) ||
      record.patientId.toLowerCase().includes(value.toLowerCase()) ||
      record.fullName.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.dateOfBirth),
    dataIndex: 'dateOfBirth',
    key: 'dateOfBirth',
    render: (_, record) =>
      record.dateOfBirth && `${calculateAge(new Date(record.dateOfBirth))}`,
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.eventLog),
    dataIndex: 'eventLog',
    key: 'eventLog',
    render: (_, record) => record.eventLogAlerts,
    sorter: (a, b) => sortWithEmptyNumbers(a.eventLogAlerts, b.eventLogAlerts),
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.currentEvent),
    dataIndex: 'currentEvent',
    key: 'currentEvent',
    render: (_, record) =>
      record.currentAlert ? (
        <AlertIndicatorCell>
          {record.currentAlert.map(alert => (
            <AlertIndicator key={alert.id} {...alert} />
          ))}
        </AlertIndicatorCell>
      ) : null,
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.patientStatus),
    dataIndex: 'patientStatus',
    key: 'patientStatus',
    render: (_, record) =>
      record.patientStatus
        ? intl.formatMessage(patientPageColumnsMessages[record.patientStatus])
        : '--',
    sorter: (a, b) => sortWithEmptyString(a.patientStatus, b.patientStatus),
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.deviceStatus),
    dataIndex: 'deviceStatus',
    key: 'deviceStatus',
    render: (_, record) =>
      record.deviceStatus
        ? intl.formatMessage(STATUS_FILTERS[record.deviceStatus].label)
        : '--',
    sorter: (a, b) => sortWithEmptyString(a.deviceStatus, b.deviceStatus),
  },
  {
    title: intl.formatMessage(patientPageColumnsMessages.isConsented),
    dataIndex: 'isConsented',
    key: 'isConsented',
    render: (_, record) => <PatientConsent record={record} />,
  },
];
